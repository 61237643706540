import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import HomeView from "../views/AboutView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: "/modals",
    name: "ModalsView",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ModalsView.vue"),
  },
  {
    path: "/serch",
    name: "Serch",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Serch.vue"),
  },
  {
    path: "/cabinet",
    name: "CabinetView",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/CabinetView.vue"),
  },
  {
    path: "/library",
    name: "LibraryView",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/LibraryView.vue"),
  },
  {
    path: "/news",
    name: "NewsView",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/NewsView.vue"),
  },
  {
    path: "/registration",
    name: "RegistrationView",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/RegistrationView.vue"),
  },
  {
    path: "/resources",
    name: "ResourcesView",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ResourcesView.vue"),
  },
  {
    path: "/personal-account",
    name: "PersonalAccount",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/PersonalAccount.vue"),
  },
  {
    path: "/resources/:id",
    name: "ResourcesPost",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ResourcesPost.vue"),
  },
  {
    path: "/news/:id",
    name: "NewsPost",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/NewsPost.vue"),
  },
  {
    path: "/event/:id",
    name: "NewsEvent",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/NewsEvent.vue"),
  },
  {
    path: "/newsscretar/:id",
    name: "NewsSecretarPost",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/NewsSecretarPost.vue"),
  },
  {
    path: "/library/:id",
    name: "LibraryPost",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/LibraryPost.vue"),
  },
  {
    path: "/strategic/:id",
    name: "StrategicPost",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/StrategicPost.vue"),
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {

    return { x: 0, y: 0 }
  }
});

export default router;
