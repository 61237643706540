export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алматинский процесс"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости и события"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Библиотека"])},
    "cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])},
    "slogon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать в ООН!"])},
    "serch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])}
  },
  "modal": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "fio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
    "Repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
    "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соглашаюсь с условиями"])},
    "Policyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политики конфиденциальности"])},
    "Forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
    "forgotCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
    "btn_reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "btn_regto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])},
    "btn_forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])},
    "btn_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "btn_forgotclose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "thank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим за регистрацию. Ваша заявка на рассмотрении. Ответ придет на указанный mail."])}
  },
  "footer": {
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться на новости"])},
    "slog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022  Almaty Process. Все права защищены"])},
    "dev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разработано в A-lux"])}
  },
  "pages": {
    "home": {
      "titles": {
        "strategic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стратегические направления"])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Библиотека"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши партнеры"])}
      },
      "btn": {
        "slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])}
      }
    },
    "about": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])}
      },
      "titles": {
        "slog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Членство Республики Узбекистан в Алматинском процессе в настоящий момент находится на этапе согласования"])},
        "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цели Алматинского процесса"])},
        "sdk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальный договор о миграции"])},
        "sdk-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«узнать больше»"])}
      }
    },
    "news": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости и события"])},
        "newsscreta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости секретариата"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать подробнее"])}
    },
    "library": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Библиотека"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать подробнее"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать документы"])}
    },
    "resources": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы"])}
      }
    },
    "cabinet": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
        "cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])}
      },
      "titles": {
        "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние новости секретариата"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы"])},
        "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материалы:"])}
      },
      "change": {
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должность"])},
        "fio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
        "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна:"])},
        "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ведомство / Министерство:"])},
        "second_fio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО:"])},
        "second_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон:"])},
        "second_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должность:"])},
        "popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши данные были сохранены"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать подробнее"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "change_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
      "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ссылка"])},
      "alternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативный контакт"])},
      "showall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все"])},
      "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Развернуть"])},
      "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
    },
    "serch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечего не найденно"])}
  },
  "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Межправительственный консультативный механизм по защите беженцев и международной миграции"])},
  "SdkSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изучите взаимосвязь между Повесткой дня до 2030 года и миграцией"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наведите курсор на значки каждой Цели в области устойчивого развития (ЦУР), чтобы узнать больше."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать больше "])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миграция может быть эффективным инструментом сокращения бедности для мигрантов и их семей и может внести значительный вклад в усилия по развитию как в странах происхождения, так и в странах назначения."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствие продовольственной безопасности может стать движущей силой миграции для отдельных лиц и их семей."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забота о здоровье и благополучии мигрантов является необходимым условием социального и экономического развития."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образование может способствовать социально-экономической интеграции детей-мигрантов и улучшить их средства к существованию во взрослом возрасте."])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нехватка воды и связанные с этим проблемы могут повлиять на уровень жизни, доступность продовольствия и здоровье, что, в свою очередь, может стать движущей силой миграции."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недорогие и альтернативные энергетические решения могут принести пользу уязвимым или перемещенным сообществам с ограниченным или отсутствующим доступом к электричеству."])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достойная работа и безопасная и надежная рабочая среда для мигрантов необходимы, если они хотят стать продуктивными членами общества и способствовать экономическому росту. В 2019 году мигранты и диаспора во всем мире перевели в страны происхождения 714 миллиардов долларов США в виде международных денежных переводов."])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миграция может стать источником расширения прав и возможностей женщин и девочек, но они также могут быть более уязвимы перед насилием, сексуальным насилием и эксплуатацией."])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мигранты могут передавать ценные знания и навыки в страны своего происхождения и назначения, помогая поддерживать развитие технологий, исследования и инновации."])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эффективное управление миграцией жизненно важно для более безопасной, упорядоченной и законной миграции, что является решающим фактором для достижения устойчивого развития."])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мигранты помогают городам процветать и становиться более динамичными, успешными центрами экономики и жизни."])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продвижение устойчивых моделей потребления и производства может помочь защитить трудящихся-мигрантов от эксплуатации."])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миграция может быть потенциальной стратегией адаптации к изменению климата и способом повышения устойчивости."])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Борьба с деградацией морских и прибрежных экосистем и диверсификация источников средств к существованию сообществ, зависящих от морских ресурсов, может помочь решить проблему вынужденного перемещения и миграции."])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вырубка лесов, деградация земель, опустынивание и утрата биоразнообразия могут иметь серьезные последствия для сообществ, средства к существованию которых зависят от природных ресурсов, и могут стать движущей силой миграции."])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более сильные, более прозрачные и подотчетные институты и улучшенный доступ к правосудию могут помочь в защите и продвижении прав мигрантов."])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Своевременные, надежные и сопоставимые данные о миграции могут помочь лицам, определяющим политику, разработать политику и планы, основанные на фактических данных, для решения миграционных аспектов ЦУР."])}
  }
}