export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almaty Process"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News and events"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabinet"])},
    "slogon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать в ООН!"])},
    "serch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
  },
  "modal": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "fio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name, last name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "Repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree with terms and conditions"])},
    "Policyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentiality agreement"])},
    "Forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "forgotCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "btn_reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "btn_regto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "btn_forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore Password"])},
    "btn_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Log in"])},
    "btn_forgotclose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "thank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for registration. Your application is under consideration. The answer will be emailed."])}
  },
  "footer": {
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "slog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022  Almaty Process. All rights reserved"])},
    "dev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developed by A-lux"])}
  },
  "pages": {
    "home": {
      "titles": {
        "strategic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategic directions"])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our partners"])}
      },
      "btn": {
        "slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])}
      }
    },
    "about": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])}
      },
      "titles": {
        "slog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Accession of the Republic of Uzbekistan to the Almaty Process is pending"])},
        "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives of the Almaty Process"])},
        "sdk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Compact for Migration"])},
        "sdk-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«To learn more»"])}
      }
    },
    "news": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News & Events"])},
        "newsscreta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secretariat News"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])}
    },
    "library": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download documents"])}
    },
    "resources": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])}
      }
    },
    "cabinet": {
      "breadcrumbs": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabinet"])}
      },
      "titles": {
        "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest news of the Secretariat"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
        "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials:"])}
      },
      "change": {
        "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functional title"])},
        "fio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
        "num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country:"])},
        "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency / Ministry:"])},
        "second_fio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "second_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number:"])},
        "second_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functional title:"])},
        "popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data has been saved"])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "change_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link"])},
      "alternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative content"])},
      "showall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
      "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
      "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
    },
    "serch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing found"])}
  },
  "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inter-State Consultation Mechanism on Refugees’ Protection and International Migration"])},
  "SdkSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore the interrelations between the 2030 Agenda and migration"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hover over the icons of each Sustainable Development Goal (SDG) to know more."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migration can be an effective poverty reduction tool for migrants and their families and can make significant contributions to development efforts in both countries of origin and destination"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food insecurity can be a driver of migration for individuals and their families."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addressing the health and well-being of migrants is a precondition for social and economic development."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education can facilitate migrant children’s socio-economic integration and improve their livelihoods as adults."])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migration can be a source of empowerment for women and girls, but they can also be especially vulnerable to violence, sexual abuse and exploitation."])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water scarcity and related issues may impact on living standards, food availability and health which in turn can be drivers of migration."])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inexpensive and alternative energy solutions can benefit vulnerable or displaced communities with limited or no access to electricity."])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decent jobs and safe and secure work environments for migrants are essential if they are to become productive members of society and contribute to economic growth. In 2019, USD 714 billion in international remittances were transferred globally by migrants and diaspora back to origin countries. "])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrants can transfer valuable knowledge and skills to their countries of origin and destination, helping to support technology development, research and innovation."])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective migration governance is vital for safer, more orderly and regular migration, which is a crucial factor to achieve sustainable development."])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrants help cities to thrive and become more vibrant, successful centres of economy and life."])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoting sustainable consumption and production patterns can help to protect migrant workers from exploitation."])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migration can be a potential climate change adaptation strategy and a way to build resilience."])},
    "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combatting marine and coastal ecosystem degradation and diversifying the livelihoods of communities that are dependent on marine resources can help address forced displacement and migration."])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deforestation, land degradation, desertification and biodiversity loss can have profound impacts on communities whose livelihoods rely on natural resources and can be drivers of migration."])},
    "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stronger, more transparent and accountable institutions and improved access to justice can help to protect and promote migrants’ rights. "])},
    "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timely, reliable and comparable data on migration can help policy makers devise evidence-based policies and plans to address the migration aspects of the SDGs."])}
  }
}