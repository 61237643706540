<template>
  <div class="about-page" v-if="Info">

    <div class="container">

      <!-- <div class="breadcrumbs">
        <a href="/" class="breadcrumbs__link">{{ $t('pages.about.breadcrumbs.home') }}</a>
        <a href="/" class="breadcrumbs__link">{{ $t('pages.about.breadcrumbs.about') }}</a>
      </div> -->
      <div class="about-page__flags">
        <img src="@/assets/images/flags/Azerbaijan.png" alt="" class="about-page__flag animate__animated animate__fadeInUp">
        <!-- <img src="@/assets/images/flags/Afghanistan.png" alt="" class="about-page__flag"> -->
        <img src="@/assets/images/flags/Kazakhstan.jpg" alt="" class="about-page__flag animate__animated animate__fadeInUp">
        <img src="@/assets/images/flags/Kyrgyzstan.png" alt="" class="about-page__flag animate__animated animate__fadeInUp">
        <img src="@/assets/images/flags/Tajikistan.png" alt="" class="about-page__flag animate__animated animate__fadeInUp">
        <img src="@/assets/images/flags/Türkiye.png" alt="" class="about-page__flag animate__animated animate__fadeInUp">
      </div>
      <div class="about-page__title animate__animated animate__backInUp">
        <img :src="Info.title.logo" alt="icon">
        <h1>{{Info.title.title}}</h1>
      </div>
  
      <div class="about-page__descr widget animate__animated animate__backInUp animate__delay-1s">
        <p v-html="Info.title.content"></p>
      </div>
      <!--  -->
      <div class="strategic-directions" id="strategic-directions">
        <div class="container"> 
          <!-- <h2 class="animate__animated " :class="{ animate__backInUp: this.strategic}">{{ $t('pages.home.titles.strategic') }}</h2> -->
          <div class="strategic-directions__items" v-if="mainInfo.strategy" >
            <router-link 
            :to="{name: 'StrategicPost', params: { id: item.id }}" 
            class="strategic-directions__item animate__animated animate__delay-1s" 
            :class="{ animate__backInUp: this.strategic}"
            v-for="(item, index) in mainInfo.strategy" 
            :key="index">
              <!-- <a :href="'strategy'/item.id" class="strategic-directions__item" v-for="(item, index) in mainInfo.strategy" :key="index"> -->
                <div class="strategic-directions__img">
                  <img :src="item.image" alt="image">
                </div>
                <div class="strategic-directions__date">{{item.title}}</div>
                <router-link :to="{name: 'StrategicPost', params: { id: item.id }}" class="home-slider__btn">{{ $t('pages.home.btn.slider') }}</router-link>
                
              <!-- <div class="strategic-directions__subtitle"><p v-html="item.content"></p></div> -->
            <!-- </a> -->
            </router-link>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="home-info chairmanship animate__animated animate__backInUp animate__delay-2s">
        <div class="container">
          <div class="chairmanship__img">
            <img :src="Info.partner.image" alt="image">
            <span class="chairmanship__footnote">{{ $t('pages.about.titles.slog') }}</span>
          </div>
          <div class="chairmanship__info">

            <div class="chairmanship__wrapper">
              <h2>{{Info.partner.title1}}</h2>
              <div class="chairmanship-current">
                <p class="chairmanship-current__descr" v-html="Info.partner.content1"></p>
              </div>
            </div>

            <div class="chairmanship__partners">
              <h2>{{Info.partner.title2}}</h2>
              <div class="chairmanship__items">
                <p class="chairmanship-members__country" v-html="Info.partner.content2"></p>
                <!-- <div class="chairmanship__item chairmanship-members">
                  <h3>
                    <img src="@/assets/images/icons/marker-yellow.svg" alt="icon">
                    <span>Участники</span>
                  </h3>
                  <div class="chairmanship-members__list">
                    <div class="chairmanship-members__country">Турция</div>
                    <div class="chairmanship-members__country">Азербайджан</div>
                    <div class="chairmanship-members__country">Казахстан</div>
                    <div class="chairmanship-members__country">Таджикистан</div>
                    <div class="chairmanship-members__country">Туркменистан</div>
                    <div class="chairmanship-members__country">Кыргызстан</div>
                    <div class="chairmanship-members__country is-disable">Афганистан</div>
                  </div>
                </div>
                <div class="chairmanship__item chairmanship-observers">
                  <h3>
                    <img src="@/assets/images/icons/marker-grey.svg" alt="icon">
                    <span>Наблюдатели</span>
                  </h3>
                  <div class="chairmanship-observers__list">
                    <div class="chairmanship-observers__country">Иран</div>
                    <div class="chairmanship-observers__country">Пакистан</div>
                    <div class="chairmanship-observers__country">Узбекистан</div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="our-goals">
        <div class="our-goals__title">
          <img src="@/assets/images/icons/goals.png" alt="icon">
          <h2 class="animate__animated "  :class="{ animate__backInUp: this.Objectives}">{{ $t('pages.about.titles.goals') }}</h2>
        </div>
        <div class="our-goals__items">
          <div class="our-goals__item widget animate__animated" :class="{ animate__backInUp: this.Objectives}" v-for="item in Info.objective.cards" :key="item.id">
            <p v-html="item.content"></p>
          </div>
        </div>
      </div>
  
      <div class="about-page__images">
        <div class="about-page__image" v-for="item in Info.objective.image" :key="item.id">
          <img :src="item.image" alt="image" >
        </div>
          
      </div>
      <div class="about-page__footer">
        <div class="about-page__footer-item " v-for="item in Info.objective.footer" :key="item.id">
          <h3 class="animate__animated"  :class="{ animate__backInUp: this.Type}">{{item.title}}</h3>
          <div class="about-page__footer-descr widget animate__animated" :class="{ animate__backInUp: this.Type}">
            <p v-html="item.content"></p>
          </div>
        </div>
      </div>
      <div class="about-page__SdkSection">
        <SdkSection />
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SdkSection from "@/components/SdkSection"

export default {
  name: "AboutView",
  components: {
    SdkSection
  },

  data() {
    return {
      Info: '',
      mainInfo: '',
      Objectives: false,
      Type: false,
    }
  },
  mounted() {    
    this.getInfo() 
    window.scrollBy(0, -10000000)
    window.onscroll = () => { 
      console.log(window.pageYOffset) 
      if(window.pageYOffset > 450) {  this.Objectives = true }
      if(window.pageYOffset > 1350) {  this.Type = true }
    }  
  },
  methods: {
    getInfo() {
      axios({
        url: 'https://api.almaty-process.org/api/about',
        headers: { 
          'Accept-Language': localStorage.getItem('lang')
        },
      })
      .then(res => {
        this.Info = res.data
      })
      axios({
        url: 'https://api.almaty-process.org/api/main',
        headers: { 
          'Accept-Language': localStorage.getItem('lang')
        },
      })
      .then(res => {
        this.mainInfo = res.data
      })
    },
  },
};
</script>
