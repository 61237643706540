<template>
  <div class="view view-migration-and-the-2030-agenda mt-4 view-id-migration_and_the_2030_agenda view-display-id-block_1 js-view-dom-id-23de14a9eb82f2f88e4725b505c671c2de000893f83629a0d8a268841e241052">
    <div class="row">







      <div class="col-sm-12 col-lg-3 attachment attachment-before" v-if="hoverBlok == 0" >
        <div class="views-element-container" style="height: 425px;">
          <div
            class="view view-migration-and-the-2030-agenda view-id-migration_and_the_2030_agenda view-display-id-attachment_1 js-view-dom-id-64fb69eafd9b1fd2c362f51b300539b1db1658454657f850f3801796709cd29e">
            <div class="view-content row" style="">
              <div class="col-xs-12 views-row">
                <div class="views-field views-field-description__value"><span class="field-content">
                    <div>
                      <h3 class="title">{{ $t('SdkSection.title') }} </h3>
                      <div class="d-sm-none d-md-block">
                        {{ $t('SdkSection.text') }}
                        <br><br>
                      </div>
                      <a href="https://iom.int/migration-sustainable-development-and-2030-agenda">
                        <img class="sdg-mobile"
                          src="@/assets/images/blockmap/sdg.png" alt="sdg">
                        </a>
                      <a href="https://iom.int/migration-sustainable-development-and-2030-agenda" class="btn btn-primary">
                        {{ $t('SdkSection.btn') }} </a>
                    </div>
                  </span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--  -->
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 1" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p> {{ $t('SdkSection.1') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 2" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_2_ZeroHunger.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.2') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 3" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_3_GoodHealthandWell-being.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.3') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 4" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_4_QualityEducation_0.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.4') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 5" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_6_CleanWaterandSanitation.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.5') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 6" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_7_AffordableandCleanEnergy.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.6') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 7" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_8_DecentWorkandEconomicGrowth.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.7') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 8" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_5_GenderEquality.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.8') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 9" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_9_Infrastructure.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.9') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 10" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_10_ReducedInequalities.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.10') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 11" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_11_SustainableCommunities.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p> {{ $t('SdkSection.11') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 12" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_12_ResponsibleConsumption.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.12') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 13" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_13_ClimatAction.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.13') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 14" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_14_LifeBelowWater.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.14') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 15" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_15_LifeonLand.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.15') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 16" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_16_PeaceJustice.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.16') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-3 " v-if="hoverBlok == 17" style="    padding-right: 0px; padding-left: 0px">
        <div class="views-element-container" style="height: 425px;">
          <div class="row description">
              <!-- <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait"> -->
                <img src="@/assets/images/blockmap/SDG_17_Partnership.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>{{ $t('SdkSection.17') }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>


<!--  -->

      <div class="col-sm-12 col-lg-9 no-padding  d-md-none d-lg-block view-content" @mouseover="mousehover = -1" @mouseleave="mousehover = 0">
        <div class="row">
          <div id="target-1960" @mouseover="hoverBlok = 1" @mouseleave="hoverBlok = 0" class="sdg-item views-row small target-1960"
            style="width: 105.75px; height: 105.75px; top: 0px; left: 317.25px;">
            <div class="image">
              <a href="https://developmentfund.iom.int/" title=" ЦУР 1 - Ликвидация нищеты "  :class="{hover: hoverBlok == 1, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/e_web_01_0.png" width="255" height="255" alt="" typeof="Image"
                  class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_1_NoPoverty.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p> Миграция может быть эффективным инструментом сокращения бедности для мигрантов и их
                    семей и может внести значительный вклад в усилия по развитию как в странах происхождения,
                    так и в странах назначения.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1961" @mouseover="hoverBlok = 2" @mouseleave="hoverBlok = 0" class="sdg-item views-row small target-1961"
            style="width: 105.75px; height: 105.75px; top: 0px; left: 423px;">
            <div class="image">
              <a href="https://www.iom.int/migration-sustainable-development-and-2030-agenda"
                title=" ЦУР 2 - Ликвидация голода"  :class="{hover: hoverBlok == 2, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/e-web-goal-02.png" width="255" height="255" alt="" typeof="Image"
                  class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_2_ZeroHunger.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Отсутствие продовольственной безопасности может стать движущей силой миграции для
                    отдельных лиц и их семей.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1962" @mouseover="hoverBlok = 3" @mouseleave="hoverBlok = 0" class="sdg-item views-row medium target-1962"
            style="width: 211.5px; height: 211.5px; top: 105.75px; left: 317.25px;">
            <div class="image">
              <a href="https://www.iom.int/migration-health" title="ЦУР 3 - Хорошее здоровье и благополучие"
                 :class="{hover: hoverBlok == 3, mousehover: mousehover == -1,}"> 
                <img src="@/assets/images/blockmap/e_web_03.png" width="255" height="255" alt=""
                  typeof="Image" class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_3_GoodHealthandWell-being.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Забота о здоровье и благополучии мигрантов является необходимым условием социального и
                    экономического развития.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1964" @mouseover="hoverBlok = 4" @mouseleave="hoverBlok = 0" class="sdg-item views-row small target-1964"
            style="width: 105.75px; height: 105.75px; top: 0px; left: 425px;">
            <div class="image">
              <a href="https://developmentfund.iom.int/" title="ЦУР 4 Качественное образование"  :class="{hover: hoverBlok == 4, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/e_web_04.png" width="255" height="255" alt="" typeof="Image"
                  class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_4_QualityEducation_0.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Образование может способствовать социально-экономической интеграции детей-мигрантов и
                    улучшить их средства к существованию во взрослом возрасте.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1963" @mouseover="hoverBlok = 5" @mouseleave="hoverBlok = 0" class="sdg-item views-row small target-1963"
            style="width: 105.75px; height: 105.75px; top: 0px; left: 634.5px;">
            <div class="image">
              <a href="https://environmentalmigration.iom.int/policy/migration-and-water"
                title="ЦУР 6 - Чистая вода и санитария"  :class="{hover: hoverBlok == 5, mousehover: mousehover == -1,}">
                 <img
                  src="@/assets/images/blockmap/e_print_06.jpg" width="255" height="255" alt="" typeof="Image"
                  class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_6_CleanWaterandSanitation.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Нехватка воды и связанные с этим проблемы могут повлиять на уровень жизни, доступность
                    продовольствия и здоровье, что, в свою очередь, может стать движущей силой миграции.</p>
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1973" @mouseover="hoverBlok = 6" @mouseleave="hoverBlok = 0" class="sdg-item views-row small target-1973"
            style="width: 105.75px; height: 105.75px; top: 0px; left: 740.25px;">
            <div class="image">
              <a href="https://environmentalmigration.iom.int/policy/migration-environment-and-climate-change-sdgs"
                title="ЦУР 7 - Недорогостоящая и чистая энергия"  :class="{hover: hoverBlok == 6, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/sdg-7.svg" alt="" typeof="Image">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_7_AffordableandCleanEnergy.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Недорогие и альтернативные энергетические решения могут принести пользу уязвимым или
                    перемещенным сообществам с ограниченным или отсутствующим доступом к электричеству.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1967" @mouseover="hoverBlok = 7" @mouseleave="hoverBlok = 0" class="sdg-item views-row medium target-1967"
            style="width: 211.5px; height: 211.5px; top: 0px; left: 846px;">
            <div class="image">
              <a href="https://www.iom.int/labour-migration" title="ЦУР 8 - Достойная работа и экономический рост"
                 :class="{hover: hoverBlok == 7, mousehover: mousehover == -1,}"> 
                <img src="@/assets/images/blockmap/e_web_08.png" width="255" height="255" alt=""
                  typeof="Image" class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_8_DecentWorkandEconomicGrowth.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Достойная работа и безопасная и надежная рабочая среда для мигрантов необходимы, если они
                    хотят стать продуктивными членами общества и способствовать экономическому росту. В 2019
                    году мигранты и диаспора во всем мире перевели в страны происхождения 714 миллиардов
                    долларов США в виде международных денежных переводов.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1965" @mouseover="hoverBlok = 8" @mouseleave="hoverBlok = 0" class="sdg-item views-row medium target-1965"
            style="width: 211.5px; height: 211.5px; top: 105.75px; left: 425px;">
            <div class="image">
              <a href="https://www.iom.int/gender-equality" title="ЦУР 5 - Гендерное равенство "  :class="{hover: hoverBlok == 8, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/e_web_05.png" width="255" height="255" alt="" typeof="Image"
                  class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_5_GenderEquality.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Миграция может стать источником расширения прав и возможностей женщин и девочек, но они
                    также могут быть более уязвимы перед насилием, сексуальным насилием и эксплуатацией.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1966" @mouseover="hoverBlok = 9" @mouseleave="hoverBlok = 0"   class="sdg-item views-row small target-1966"
            style="width: 105.75px; height: 105.75px; top: 105.75px; left: 740.25px;">
            <div class="image">
              <a href="https://developmentfund.iom.int/"
                title="ЦУР 9 - Индустриализация, инновации и инфраструктура” style=" opacity:=""  :class="{hover: hoverBlok == 9, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/e_sdg-goals_icons-individual-rgb-09.png" width="255" height="255" alt=""
                  typeof="Image" class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_9_Infrastructure.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Мигранты могут передавать ценные знания и навыки в страны своего происхождения и
                    назначения, помогая поддерживать развитие технологий, исследования и инновации.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1968" @mouseover="hoverBlok = 10" @mouseleave="hoverBlok = 0"   class="sdg-item views-row large target-1968"
            style="width: 317.25px; height: 317.25px; top: 0px; left: 0px;">
            <div class="image">
              <a href="https://www.iom.int/migration-sustainable-development-and-2030-agenda"
                title="ЦУР 10 - Уменьшение неравенства"  :class="{hover: hoverBlok == 10, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/e_web_10.png" width="255" height="255" alt="" typeof="Image"
                  class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_10_ReducedInequalities.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Эффективное управление миграцией жизненно важно для более безопасной, упорядоченной и
                    законной миграции, что является решающим фактором для достижения устойчивого развития.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1969" @mouseover="hoverBlok = 11" @mouseleave="hoverBlok = 0"   class="sdg-item views-row small target-1969"
            style="width: 105.75px; height: 105.75px; top: 211.5px; left: 740.25px;">
            <div class="image">
              <a href="https://www.iom.int/shelter" title="ЦУР 11 - Устойчивые города и населенные пункты"
                 :class="{hover: hoverBlok == 11, mousehover: mousehover == -1,}"> 
                <img src="@/assets/images/blockmap/e_web_11.png" width="255" height="255" alt=""
                  typeof="Image" class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_11_SustainableCommunities.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Мигранты помогают городам процветать и становиться более динамичными, успешными центрами
                    экономики и жизни.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1976" @mouseover="hoverBlok = 12" @mouseleave="hoverBlok = 0"   class="sdg-item views-row small target-1976"
            style="width: 105.75px; height: 105.75px; top: 317.25px; left: 423px;">
            <div class="image">
              <a href="https://www.iom.int/migration-sustainable-development-and-2030-agenda"
                title="ЦУР 12 - Ответственное потребление и производство"  :class="{hover: hoverBlok == 12, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/sdg-12[1].svg" alt="" typeof="Image">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_12_ResponsibleConsumption.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Продвижение устойчивых моделей потребления и производства может помочь защитить
                    трудящихся-мигрантов от эксплуатации.</p>
  
                </div>
              </div>
            </div>
          </div>
          <div id="target-1970" @mouseover="hoverBlok = 13" @mouseleave="hoverBlok = 0"   class="sdg-item views-row medium target-1970"
            style="width: 211.5px; height: 211.5px; top: 211.5px; left: 846px;">
            <div class="image">
              <a href="https://www.iom.int/migration-environment-and-climate-change"
                title="ЦУР 13 - Борьба с изменением климата"  :class="{hover: hoverBlok == 13, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/e_web_13.png" width="255" height="255" alt="" typeof="Image"
                  class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_13_ClimatAction.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Миграция может быть потенциальной стратегией адаптации к изменению климата и способом
                    повышения устойчивости.</p>
                </div>
              </div>
            </div>
          </div>
          <div id="target-1975" @mouseover="hoverBlok = 14" @mouseleave="hoverBlok = 0"   class="sdg-item views-row small target-1975"
            style="width: 105.75px; height: 105.75px; top: 317.25px; left: 425px;">
            <div class="image">
              <a href="https://environmentalmigration.iom.int/policy/migration-and-oceans"
                title="ЦУР 14 - Сохранение морских экосистем"  :class="{hover: hoverBlok == 14, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/sdg-14[1].svg" alt="" typeof="Image">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_14_LifeBelowWater.jpg" width="344" height="533" alt=""
                typeof="Image" class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Борьба с деградацией морских и прибрежных экосистем и диверсификация источников средств к
                    существованию сообществ, зависящих от морских ресурсов, может помочь решить проблему
                    вынужденного перемещения и миграции.
                  </p>
  
                </div>
              </div>
            </div>
          </div>
          <div id="target-1974" @mouseover="hoverBlok = 15" @mouseleave="hoverBlok = 0"   class="sdg-item views-row medium target-1974"
            style="width: 211.5px; height: 211.5px; top: 317.25px; left: 0px;">
            <div class="image">
              <a href="https://www.iom.int/migration-environment-and-climate-change"
                title="ЦУР 15 - Сохранение экосистем суши"  :class="{hover: hoverBlok == 15, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/sdg-15[1].svg" alt="" typeof="Image">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_15_LifeonLand.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Вырубка лесов, деградация земель, опустынивание и утрата биоразнообразия могут иметь
                    серьезные последствия для сообществ, средства к существованию которых зависят от природных
                    ресурсов, и могут стать движущей силой миграции. </p>
  
                </div>
              </div>
            </div>
          </div>
          <div id="target-1971" @mouseover="hoverBlok = 16" @mouseleave="hoverBlok = 0"   class="sdg-item views-row medium target-1971"
            style="width: 211.5px; height: 211.5px; top: 317.25px; left: 211.5px;">
            <div class="image">
              <a href="https://www.iom.int/migrant-protection-and-assistance"
                title="ЦУР 16 - Мир, правосудие и эффективные институты"  :class="{hover: hoverBlok == 16, mousehover: mousehover == -1,}"> 
                <img
                  src="@/assets/images/blockmap/e_web_16.png" width="255" height="255" alt="" typeof="Image"
                  class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden">
              <img src="@/assets/images/blockmap/SDG_16_PeaceJustice.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Более сильные, более прозрачные и подотчетные институты и улучшенный доступ к правосудию
                    могут помочь в защите и продвижении прав мигрантов. </p>
  
                </div>
              </div>
            </div>
          </div>
          <div id="target-1972" @mouseover="hoverBlok = 17" @mouseleave="hoverBlok = 0"  class="sdg-item views-row medium target-1972"
            style="width: 211.5px; height: 211.5px; top: 317.25px; left: 634.5px;">
            <div class="image">
              <a href="https://www.iom.int/international-cooperation-and-partnerships"
                title="ЦУР 17 - Партнерство в интересах устойчивого развития" :class="{hover: hoverBlok == 17, mousehover: mousehover == -1,}"> 
                <img src="@/assets/images/blockmap/e_web_17.png" width="255" height="255" alt="" typeof="Image" class="image-style-medium">
                </a>
              <div
                class="field field--name-dynamic-token-fieldnode-edit-link field--type-ds field--label-hidden field__item">
              </div>
            </div>
            <div class="row description hidden" >
              <img src="@/assets/images/blockmap/SDG_17_Partnership.jpg" width="344" height="533" alt="" typeof="Image"
                class="image-style-portrait">
              <div class="col-xs-12">
                <div>
                  <p>Своевременные, надежные и сопоставимые данные о миграции могут помочь лицам, определяющим
                    политику, разработать политику и планы, основанные на фактических данных, для решения
                    миграционных аспектов ЦУР.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="SdkSection-row">
    <p class="SdkSection-text">
        <a href="https://www.un.org/ru/migration2022/global-compact-for-migration">{{ $t('pages.about.titles.sdk') }}</a>
    </p>
    <a href="https://www.un.org/ru/migration2022/global-compact-for-migration">
      <img src="@/assets/images/PR_Logo_GCM.png" alt="" style="">
    </a>
  </div>
  
  </template>
  <script>
    export default {
      name: "SdkSection",
      components: {},
      data() {
        return {
          blok17: false,
          hoverBlok: 0,
          mousehover: 0,
          lang: '',
        }
      },
      mounted() {    
          this.getLang() 
      },
      methods: {
        hover17() {
          console.log('lol lol')
          this.hoverBlok = 1
        },
        getLang() {
        const lang = localStorage.getItem('lang')
        console.log(lang)
        if (lang == null) {
          localStorage.setItem('lang', 'ru')
          location.reload();
        } else {
          this.lang = lang
        }
      },
      }
    };

  </script>

  <style>
  
@import "@/assets/css/blockmap.css";
@import "@/assets/css/blockmapsec.css";
    .mousehover{
      opacity: 0.2;
    }
    .hover {
      opacity: 1;
    }
  </style>