<template>
  <div class="default-layout">
    <app-header />
    <div class="banner-top">
      <div class="container">
        <router-link :to="{ name: 'home'}"> <img src="@/assets/images/icons/banner.png" alt="banner logo"  v-animate-css.hover="'rotateIn'"></router-link>
        <h2>{{ $t('layout') }}</h2>
      </div>
    </div>
    <slot></slot>
    <!-- <div class="container"> -->
    <!-- </div> -->
    <app-footer />
  </div>
</template>

<script setup name="DefaultLayout">
import { defineAsyncComponent } from "@vue/runtime-core";
import 'animate.css';

const AppHeader = defineAsyncComponent(() =>
  import("@/components/shared/AppHeader")
);
const AppFooter = defineAsyncComponent(() =>
  import("@/components/shared/AppFooter")
);
</script>
