import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTheMask from 'vue-the-mask'
import moment from 'moment';
import "./assets/scss/style.scss";
import i18n from './i18n'
import VAnimateCss from 'v-animate-css';

createApp(App).use(i18n)
    .use(store)
    .use(router)
    .use(VueTheMask)
    .use(VAnimateCss)
    .use(moment)
    .mount("#app");

